body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  margin: 0 auto;
  border-left: solid 1px #888888;
  border-right: solid 1px #888888;
}

@media screen and (max-width: 480px) {
  .App {
    border-left: none;
    border-right: none;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.w-50px {
  width: 50px;
}

.w-70px {
  width: 70px;
}
.w-100px {
  width: 100px;
}
.w-150px {
  width: 150px;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-400px {
  width: 400px;
}
.m-5px {
  margin-top: 5px;
}
.textTitle {
  font-size: 18px;
  align-items: left;
  justify-content: left;
}
.textBody {
  font-size: 18px;
}

.famikarColor {
  color:#545454;
  border-color:#00CDAC;
}

.diagnosis-text {
  width: 80%;
  width: calc(100% - 5em);
}

.p-grid__list-images {
  display: flex;
  flex-flow: wrap;
}
.p-grid__list-images > .p-media__thumb {
  margin: .5rem;
  width: calc(50% - 1rem);
}
.p-media__thumb {
  position: relative;
  overflow: hidden;
  width: 100%
}
.p-media__thumb::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.p-media__thumb > img {
  position: absolute;
  object-fit: cover;
  object-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

